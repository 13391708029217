<template>
  <div class="user-avatar-edit">
    <div class="header">
      <van-uploader :max-size="5000 * 1024" :after-read="faceAfterRead" @oversize="onOversize">
        <div class="top">
          <img :src="avatar" class="avatar">
          <img :src="icon" class="icon">
        </div>
      </van-uploader>
    </div>
    <div class="list">
      <div v-for="item in avatarList" :key="item.title" class="item" @click="goSelected(item)">
        <div class="avatar" :class="{ border: item.imageURL == orginAvatar }"><img :src="item.imgUrl" alt=""></div>
        <div class="title">{{ item.title }}</div>
      </div>
    </div>
    <div class="footer">
      <van-button type="primary" size="normal" round block color="linear-gradient(136deg, #FFB367 0%, #FF7700 100%)" @click="onSubmit">完成</van-button>
    </div>
  </div>
</template>
<script>
import { getDefaultAatar, changeHeadImg } from '@/api/user'
import { filesAnon } from '@/api/file'
import { getOssUrl } from '@/utils/oss'
import { mapGetters } from 'vuex'
import icon from '@/assets/user/upload.png'
export default {
  data() {
    return {
      avatarList: [],
      avatar: '', // 当前显示的头像，已经oss处理
      orginAvatar: '', // 当前原始头像，未oss处理
      uploadFile: '', // 当前上传的头像
      icon
    }
  },
  computed: {
    ...mapGetters([
      'userInfos'
    ])
  },
  async mounted() {
    this.orginAvatar = this.userInfos.headImgUrl
    this.avatar = await getOssUrl(this.userInfos.headImgUrl)
    this.getDefaultAatar()
  },
  methods: {
    onOversize() {
      this.$toast('图片大小不能超过5mb！')
    },
    goSelected(item) {
      this.avatar = item.imgUrl
      this.orginAvatar = item.imageURL
      this.uploadFile = ''
    },
    async faceAfterRead(e) {
      this.avatar = e.content
      this.orginAvatar = e.content
      this.uploadFile = e
      console.log(e, '22')
    },
    async onSubmit() {
      const params = {
        isDefault: 1,
        headImgUrl: this.orginAvatar
      }
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      if (this.uploadFile) {
        const file = new FormData() // 创建form对象
        file.append('file', this.uploadFile.file) // 通过append向form对象添加数据
        const res = await filesAnon(file)
        params.headImgUrl = res.datas && res.datas.url
        params.isDefault = 0
      }
      const res = await changeHeadImg(params)
      loading.clear()
      const msg = params.isDefault === 1 ? res.resp_msg : '头像已上传成功待后台审核完成即可使用'
      this.$toast({
        message: msg,
        onClose: () => {
          this.$router.go(-1)
        }
      })
    },
    async getDefaultAatar() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await getDefaultAatar()
      const list = res.datas
      for (let i = 0; i < list.length; i++) {
        list[i].imgUrl = await getOssUrl(list[i].imageURL)
      }
      this.avatarList = list
      loading.clear()
    }
  }
}
</script>
<style lang="scss" scoped>
.user-avatar-edit {
  .header {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 50px 0;
    box-shadow: 0px 1px 1px rgba(0,0,0,0.06);
    .avatar {
      width: 74px;
      height: 74px;
      border: 1px solid #ccc;
      border-radius: 100%;
    }
    .icon {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 20px;
      height: 20px;
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    .item {
      width: 33.33%;
      padding: 0 10px;
      box-sizing: border-box;
      text-align: center;
      .title {
        padding: 25px 0;
        font-size: 12px;
        color: #32465A;
      }
      .avatar {
        padding: 10px;
        &.border {
          border: 1.5px solid #FF7700;
          border-radius: 4px;
        }
      }
    }
  }
  .footer {
    padding: 20px;
  }
}
</style>
